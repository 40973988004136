<template>
  <div class="product-card">
    <div class="side-picture">
      <img
        v-if="!product.files_attachments"
        src="@/assets/svg/v2/bottle.svg"
        alt="Bottle"
        class="no-picture"
      />
      <img
        v-else-if="product.files_attachments.length === 0"
        src="@/assets/svg/v2/bottle.svg"
        alt="Bottle"
        class="no-picture"
      />
      <img
        v-else-if="product.files_attachments.length > 0"
        :src="product.files_attachments[0]"
        alt="Bottle picture preview"
      />
    </div>
    <div class="side-info">
      <div class="title">
        {{ product.title || product.name }}
        <span v-if="product.name"> : {{ $t('search.bottler_page') }}</span>
      </div>
      <div class="details">
        <div v-if="product.ticker_maitre" class="ticker-maitre">
          {{ product.ticker_maitre }}
        </div>
        <div v-if="matchingPortfolio" class="portfolio">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('search.bottle_in_portolio')"
            placement="top"
            >
            <img
              src="@/assets/svg/v2/portfolioActive.svg"
              alt="Bottle in portfolio"
            />
          </el-tooltip>
        </div>
        <div v-if="product.reviews_count > 2" class="rating-product">
          <img
            src="@/assets/svg/v2/starPlain_1.svg"
            class="themed-img"
            alt="Product rating"
            width="15px"
          />
          <span
            >{{ product.reviews_avg_score }}/100 ({{
              product.reviews_count
            }})</span
          >
        </div>
      </div>
      <div class="live-orders">
        <div class="case-buy">
          <button v-if="product.buy_orders" class="live-order-buy">
            <img
              src="@/assets/svg/v2/products/buyOrderLight.svg"
              alt="Buy order"
              width="16"
              height="16"
            />
            <span> {{ $t('search.buy') }} ({{ product.buy_orders }})</span>
          </button>
          <div v-else class="empty-order">
            <img
              src="@/assets/svg/v2/products/buyOrderInactive.svg"
              class="themed-img"
              alt="Buy order"
            />
            <p>{{ $t('search.buy') }} (0)</p>
          </div>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('search.alert_buy_on')"
            placement="bottom"
            >
            <img
              v-if="matchingBuyAlert"
              src="@/assets/svg/v2/alertBuy.svg"
              alt="Alert active buy"
              class="alert-buy"
           />
          </el-tooltip>
        </div>
        <div class="case-sell">
          <button v-if="product.sell_orders" class="live-order-sell">
            <img
              src="@/assets/svg/v2/products/sellOrderLight.svg"
              alt="Sell order"
              width="16"
              height="16"
            />
            <span> {{ $t('search.sell') }} ({{ product.sell_orders }})</span>
          </button>
          <div v-else class="empty-order">
            <img
              src="@/assets/svg/v2/products/sellOrderInactive.svg"
              class="themed-img"
              alt="Sell order"
            />
            <p>{{ $t('search.sell') }} (0)</p>
          </div>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('search.alert_sell_on')"
            placement="bottom"
            >
            <img
              v-if="matchingSellAlert"
              src="@/assets/svg/v2/alertSell.svg"
              alt="Alert active sell"
              class="alert-sell"
            />
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'searchResultsCard',
  props: {
    product: Object
  },
  computed: {
    ...mapGetters({
      informations: 'getComplementaryInfo'
    }),
    matchingPortfolio() {
      return this.informations?.portfolio?.includes(this.product.ticker_maitre) || false;
    },
    matchingBuyAlert() {
      return this.informations?.ordersAlerts?.some(
        alert => alert.isbn === this.product.isbn && alert.type === 0
      ) || false;
    },
    matchingSellAlert() {
      return this.informations?.ordersAlerts?.some(
        alert => alert.isbn === this.product.isbn && alert.type === 1
      ) || false;
    }
  },
  methods: {
    ...mapActions(['complementaryInfo'])
  }
};
</script>




<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.product-card,
.side-picture,
.side-info,
.details,
.live-order,
.case-buy,
.case-sell,
.live-order-buy,
.live-order-sell,
.empty-order {
  display: flex;
}

.product-card {
  box-sizing: border-box;
  width: 445px;
  height: 145px;
  border: 1px solid var(--border-2);
  border-radius: 12px;
  align-items: center;
  background: var(--bg-search-card);
  color: $dark-color;
  box-shadow: var(--box-shadow-6);
  cursor: pointer;

  .side-picture {
    width: 30% !important;
    align-items: center;
    overflow: hidden;

    img {
      box-sizing: border-box;
      width: 100%;
      min-height: 145px;
      object-fit: cover;
      padding: 3px 0 3px 3px;
      border-radius: 12px 0 0 12px;
    }

    .no-picture {
      transform: scale(70%);
      object-fit: contain;
      height: 110px;
    }
  }

  .side-info {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5px 10px 5px 8px;
    margin-right: 12px;
    justify-content: space-between;

    .title {
      font-weight: 600;
      margin-top: 12px;
      color: var(--text-color);
    }

    .details {
      justify-content: space-between;
      align-items: center;
      color: var(--text-color);

      .ticker-maitre {
        border-radius: 6px;
        border: 1px solid var(--text-color);
        background: transparent;
        padding: 3px;
        font-size: 14px;
        width: fit-content;
      }
    }

    .live-orders {
      width: 100%;
      margin-bottom: 10px;

      .case-buy,
      .case-sell {
        width: fit-content;
        justify-content: right;
        text-align: right;

        .empty-order {
          display: flex;
          justify-content: center;
          border-radius: 50px;
          border: 1px solid var(--border);
          height: 27px;
          width: fit-content;
          padding: 0 8px;
        }
      }


      .case-buy .empty-order {
        margin-left: 0 !important;
      }
      .case-sell .empty-order {
        margin-left: 12px;
      }
    }

    .live-order-buy,
    .live-order-sell {
      display: flex;
      border-radius: 50px;
      border: 1px solid var(--border);
      box-shadow: var(--box-shadow-6);
      height: 27px;
      width: 90px;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 50%;
      }

      p,
      span {
        color: var(--text-color);
        font-size: 15px;
        font-weight: 500;
      }

      p {
        margin-left: 6px;
      }

      span {
        margin-left: 5px;
      }
    }

    .live-order-buy {
      background: $bg-gradient-buy;
      img {
        background: var(--buysuccess-color);
      }
    }

    .live-order-sell {
      margin-left: 12px;
      background: $bg-gradient-sell;
      img {
        background: $sell-color;
      }
    }

    .alert-buy,
    .alert-sell {
      margin-left: 5px;
    }
  }
}

@media (max-width: 500px) {
  .product-card {
    width: 95%;
    margin: auto;
  }
  .side-info {
    width: 65%; 
  }

  .live-orders {
    width: 100%;
  }
  .case-buy,
  .case-sell {
    width: fit-content !important;
  }
  .live-order-buy,
  .live-order-sell {
    width: 100%;
    justify-content: center;
  }
}

</style>
