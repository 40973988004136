<template>
    <div class="filter-modal">
        <div class="filter-category">
            <div class="filter-country">
                <p>{{ $t('product_page.spirit_type') }} :</p>
                <el-select
                    :placeholder="$t('product_page.spirit_type')"
                    filterable
                    clearable
                    v-model="searchFilters.spirit_type"
                    :effect="darkmode ? 'dark' : ''"
                >
                <el-option
                    v-for="item in getLists.spirit_type"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
                </el-select>
            </div>

            <div v-if="loadingDelay" class="filter-country">
                <p>{{ $t('register.country') }} :</p>
                <el-select
                    :placeholder="$t('register.country')"
                    filterable
                    multiple
                    clearable
                    v-model="searchFilters.country"
                    :effect="darkmode ? 'dark' : ''"
                >
                <el-option
                    v-for="item in getFilters.country"
                    :key="item.value"
                    :label="item.country"
                    :value="item.value"
                />
                </el-select>
            </div>

            <div class="filter-age">
                <p>{{ $t('product_page.age') }} :</p>
                <el-input
                    class="input"
                    v-model="searchFilters.age"
                    placeholder="18 | 18,19,20 | 18-20"
                    clearable
                    :effect="darkmode ? 'dark' : ''"
                />
            </div>

            <div class="filter-vintage">
                <p>{{ $t('product_page.vintage') }} :</p>
                <el-input
                    class="input"
                    v-model="searchFilters.vintage"
                    placeholder="2002 | 2002,2003 | 2002-2005"
                    clearable
                    :effect="darkmode ? 'dark' : ''"
                />
            </div>

            <div class="double-filters filter-sc">
                <div class="double-filter-first">
                    <p>Single cask :</p>
                    <el-switch v-model="searchFilters.single_cask" />
                </div>
            </div>
        </div>

        <button @click="searchWithFilters()" class="filter-btn action-light">
            {{ $t('filters.filter') }}
        </button>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import { inject } from "vue"

  export default {
    emits: ['search', 'close'],
    data() {
      return {
        darkmode: inject("darkmode"),
        loadingDelay: false,
        searchFilters: {
            spirit_type: null,
            country: null,
            age: null,
            vintage: null,
            single_cask: false
        }
      }
    },
    computed: {
        ...mapGetters({
            getFilters: 'getFilters',
            getLists: 'getLists',
            getFiltersHistory: 'getFiltersHistory'
        })
    },
    methods: {
        searchWithFilters() {
            this.$emit('search', this.searchFilters);
            this.$emit('close');
        }
    },
    created() {
        if (this.getFiltersHistory) {
            this.searchFilters = {
                spirit_type: this.getFiltersHistory.spirit_type,
                country: this.getFiltersHistory.country,
                age: this.getFiltersHistory.age,
                vintage: this.getFiltersHistory.vintage,
                single_cask: this.getFiltersHistory.single_cask
            }

            setTimeout(() => {
                this.loadingDelay = true;
            }, 300);
        } else {
            this.loadingDelay = true;
        }
    },
    mounted() {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                this.$emit('search', this.searchFilters);
                this.$emit('close');
            }
        });
    }
  }
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.filter-modal {
    padding: 0 20px;
}

.filter-country, .filter-age, .filter-vintage, .filter-sc {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
        width: 50%;
    }
}

.filter-sc {
    justify-content: left;
}

.double-filters {
    
    .double-filter-first {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
    }
}

.input {
  border-radius: 5px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
}

.filter-btn {
    padding: 14px 20px;
    margin: auto;
    border-radius: 52px!important;
    background: $primary-color;
    color: var(--text-color);
    border: none!important;
}

@media screen and (max-width: 500px) {
    .filter-modal {
        padding: 0;
    }

    .filter-country, .filter-age, .filter-vintage {

        p {
            width: 100%;
        }
    }

    .double-filter-first {
        width: 100%!important;
    }
}
</style>
  